import { Row, Col, Container } from "react-bootstrap";
import 'animate.css';
import userImg from "../assets/img/user.jpeg";
import profImg from "../assets/img/prof.jpeg";

export const Benefits = () => {
  const benefit = [
    {
      name: "Benefits For Patients",
      description: [
        {
          title: "24/7 AI Health Assistant",
          content: "Always available for logging symptoms, answering questions, and providing initial assessments. Generates detailed anamnesis reports to prepare you for your doctor consultation."
        },
        {
          title: "Tailored Support Networks",
          content: "Join verified communities of peers who understand your health journey. Exchange advice, discuss treatments, and gain emotional support in a safe, moderated environment."
        },
        {
          title: "Access to Top Specialists",
          content: "Get connected to experts most suited to your health needs through advanced matching algorithms. View transparent doctor profiles with verified credentials and reviews."
        },
        {
          title: "End-to-End Convenience",
          content: "From consultations to lab tests, manage everything in one place. Order at-home medical kits, book home nursing services, and receive lab results seamlessly."
        },
        {
          title: "Enhanced Privacy and Security",
          content: "All personal and medical data is encrypted and stored securely. Our platform is fully compliant with HIPAA and GDPR regulations to protect your privacy."
        },
        {
          title: "Personalized Health Insights",
          content: "Receive customized recommendations for lifestyle improvements, diets, and exercises. Access regular health reports that track your progress and provide actionable feedback."
        }
      ],
      imageUrl: userImg
    },
    {
      name: "Benefits For Professionals",
      description: [
        {
          title: "Simplified Diagnoses",
          content: "Save time and improve accuracy with real-time AI insights and diagnostic suggestions. Use LLM-powered transcription and analysis to streamline consultations."
        },
        {
          title: "Enhanced Patient Engagement",
          content: "Recommend verified communities and follow-up actions directly through the platform. Build stronger relationships with patients through personalized care and advice."
        },
        {
          title: "Revenue Growth",
          content: "Earn more with personalized consultations, subscription-based services, and expanded patient reach. Set your own consultation fees and retain control over your pricing."
        },
        {
          title: "Professional Development",
          content: "Join a platform designed for cutting-edge healthcare delivery. Access tools that keep you updated with the latest medical knowledge and guidelines."
        },
        {
          title: "Streamlined Workflow",
          content: "Simplify administrative tasks with integrated scheduling, billing, and patient management tools. Securely store and access patient data, lab results, and consultation histories in one place."
        },
        {
          title: "Collaborative Care Opportunities",
          content: "Collaborate with other healthcare professionals within the platform for complex cases. Share and review lab results, community discussions, and nursing reports in real-time."
        },
        {
          title: "Secure and Compliant Ecosystem",
          content: "Benefit from end-to-end encryption and compliance with HIPAA, GDPR, and local regulations. Safeguard sensitive patient data with advanced security protocols."
        }
      ],
      imageUrl: profImg
    }
  ];
  

  return (
    <section className="benefits" id="benefits">
      <Container>
      <div className="skill-bx wow zoomIn">
        <Row>
          <Col>
            <h1>Benefits</h1>
            <p>This platform bridges the gap between user-driven AI health assistance and expert medical consultations, while actively incorporating professional expertise and tailoring the models to individual users’ needs. The result is a comprehensive, efficient, and personalized healthcare experience for all.</p>
          </Col>
        </Row>
        <Row>
          {benefit.map((member, index) => (
            <Col lg={6} md={6} key={index}>
              <div className="benefits-box">
                <img src={member.imageUrl} alt={member.name} />
                <h3>{member.name}</h3>
                {member.description.map((item, i) => (
                  <p key={i}>
                    <strong>{item.title}:</strong> {item.content}
                  </p>
                ))}
              </div>
            </Col>
          ))}
        </Row>
        </div>
      </Container>
    </section>
  )
}
