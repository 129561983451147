import React from 'react';
import { Container, Row, Col, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserPlus,
  faRobot,
  faUserMd,
  faUsers,
  faIdCard,
  faUserCheck,
  faBrain,
  faCheckCircle,
  faFirstAid
} from '@fortawesome/free-solid-svg-icons';
import './HowItWorks.css';

export const HowItWorks = () => {
  const [selectedCategory, setSelectedCategory] = React.useState("forPatients");

  const categories = [
    { category: "forPatients", label: "For Patients" },
    { category: "forDoctors", label: "For Professionals" }
  ];

  const timelineData = {
    forPatients: [
      {
        icon: faUserPlus,
        title: "Sign Up and Verify Your Profile",
        description: "Complete a quick registration and provide initial health details for better matching."
      },
      {
        icon: faRobot,
        title: "Talk to Our AI Health Assistant",
        description: "Log your symptoms, receive initial insights, and prepare for your doctor consultation."
      },
      {
        icon: faUserMd,
        title: "Book Your Doctor",
        description: "Choose from our recommended specialists based on your needs."
      },
      {
        icon: faFirstAid,
        title: "Get Your MedKit",
        description: "Receive personalized medical kits provided by our platform. These devices seamlessly connect to our system for real-time health monitoring and better care."
      },
      {
        icon: faUsers,
        title: "Join a Community",
        description: "After a doctor's verification, join a support group tailored to your health condition."
      }
    ],
    forDoctors: [
      {
        icon: faIdCard,
        title: "Create Your Profile",
        description: "Highlight your specialties and set your availability."
      },
      {
        icon: faUserCheck,
        title: "Receive Patient Matches",
        description: "Our algorithm ensures you connect with the right patients."
      },
      {
        icon: faBrain,
        title: "Leverage AI Assistance",
        description: "Use our tools to transcribe, diagnose, and manage your consultations effectively."
      },
      {
        icon: faCheckCircle,
        title: "Verify and Recommend",
        description: "Help patients join communities that can further support their recovery journey."
      }
    ]
  };

  return (
    <section className="project" id="how-it-works">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>How It Works</h2>
              <p>Follow these simple steps to get started with Dr.Medkit</p>
              
              <Nav variant="pills" className="category-nav mb-4 justify-content-center align-items-center">
                {categories.map((cat) => (
                  <Nav.Item key={cat.category}>
                    <Nav.Link 
                      className={`category-nav-link ${selectedCategory === cat.category ? 'active' : ''}`}
                      onClick={() => setSelectedCategory(cat.category)}
                    >
                      {cat.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              <div className="how-it-works-container">
                {timelineData[selectedCategory].map((item, index) => (
                  <div key={index} className="how-it-works-item">
                    <div className="icon-box">
                      <FontAwesomeIcon icon={item.icon} className="icon" />
                    </div>
                    <div className="content">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};