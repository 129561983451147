import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/logo.png";
import 'animate.css';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(80 - Math.random() * 30);
  const toRotate = [ "Where AI Meets Professional Healthcare", "United with AI Precision, Doctor Expertise" ];
  const period = 1000;

  useEffect(() => {
    const tick = () => {
      let i = loopNum % toRotate.length;
      let fullText = toRotate[i];
      let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

      setText(updatedText);

      if (isDeleting) {
        setDelta(prevDelta => prevDelta / 2);
      }

      if (!isDeleting && updatedText === fullText) {
        setIsDeleting(true);
        setDelta(period);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setDelta(80);
      }
    };

    let ticker = setInterval(tick, delta);
    return () => clearInterval(ticker);
  }, [text, delta, loopNum, isDeleting]);

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
              {
              <div>
                <div className="banner-text-wrapper">
                  <h1>{`Dr.Medkit: `}
                    <span className="txt-rotate" dataPeriod="1000" data-rotate='Where AI Meets Professional Health'>
                      <span className="wrap">{text}</span>
                    </span>
                  </h1>
                </div>
              </div>
              }
          </Col>
          <Col xs={12} md={6} xl={5}>
              {
                <div>
                  <img src={headerImg} alt="Header Img" className="floating-logo"/>
                </div>
              }
          </Col>
        </Row>
      </Container>
    </section>
  )
}
