import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>What is Dr.Medkit?</h2>
                        <p>DrMedKit is a next-generation telemedicine platform designed to bridge the gap between doctors and patients. Our mission is to provide advanced AI-assisted diagnostics, seamless doctor-patient communication, and a unique community feature where verified patients can connect with others facing similar health challenges. Whether you're looking for expert medical advice, a personalized diagnosis, or a support network to share experiences and insights, DrMedKit is here to revolutionize how healthcare is delivered and experienced.</p>
                        <div className="feature-grid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
