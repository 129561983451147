import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import './VideoSection.css';

export const VideoSection = () => {
  return (
    <section className="video-section" id="video">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} md={11} sm={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className="video-box wow zoomIn">
                  <h2>Check Our MVP</h2>
                  <div className="video-container">
                    <iframe 
                      src="https://www.youtube.com/embed/z19I_5JqvYs?si=HzdYFByGkUxSWysA&playbackRate=2.5"
                      title="YouTube video player" 
                      frameBorder="0" 
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
} 