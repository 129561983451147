import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield, faStethoscope, faHeartbeat, faHandsHelping, faMobileAlt, faStar } from "@fortawesome/free-solid-svg-icons";
import TrackVisibility from 'react-on-screen';

export const WhyChoose = () => {
  const [selectedCategory, setSelectedCategory] = React.useState("forPatients");

  const data = {
    "forPatients": {
      "benefits": [
        {
          "title": "Personalized Healthcare",
          "icon": faHeartbeat,
          "points": [
            "Your Health, Your Way: We ensure that your unique needs are matched with the best doctors and supportive communities tailored to your specific conditions.",
            "AI That Listens: Receive recommendations and insights crafted just for you, ensuring every step in your health journey is meaningful."
          ]
        },
        {
          "title": "Empathy Meets Expertise",
          "icon": faHandsHelping,
          "points": [
            "More Than Just Medicine: Beyond consultations, join communities of real people who understand your journey, providing a space for shared experiences and advice.",
            "A Partner in Your Recovery: Connect with verified groups where compassion meets expertise to empower you in making better health decisions."
          ]
        },
        {
          "title": "Convenience at Its Best",
          "icon": faMobileAlt,
          "points": [
            "Health in Your Pocket: From consultations to lab results, everything is managed through our secure, user-friendly platform that works seamlessly across all devices.",
            "Hassle-Free Labs: Get your medical kits delivered to your home and see your test results directly in your profile—no clinic visits required.",
            "24/7 Availability: With AI-powered tools, assistance is just a tap away, anytime, anywhere."
          ]
        },
        {
          "title": "Exclusive Features",
          "icon": faStar,
          "points": [
            "Join community webinars for peer advice and professional tips.",
            "Participate in health challenges designed to motivate recovery and better habits.",
            "Get real-time alerts about trends or updates in your community’s health area."
          ]
        }
      ]
    },
    "forDoctors": {
      "benefits": [
        {
          "title": "Augment Your Expertise",
          "icon": faStethoscope,
          "points": [
            "Your AI-Powered Assistant: Harness the power of cutting-edge AI to deliver faster, more accurate diagnoses with real-time insights and transcriptions during consultations.",
            "Stay Ahead: Access the latest medical knowledge, research-backed guidelines, and dynamic suggestions to ensure you're always on top of your game."
          ]
        },
        {
          "title": "Efficient Workflow",
          "icon": faUserShield,
          "points": [
            "We Handle the Details: Focus entirely on patient care while we manage the technicalities—matching, scheduling, data integration, and post-consultation follow-ups.",
            "One-Stop Platform: Manage appointments, view patient histories, and access reports effortlessly, reducing time spent on admin work by 60%."
          ]
        },
        {
          "title": "Expand Your Reach",
          "icon": faHandsHelping,
          "points": [
            "Be the Go-To Expert: Treat patients from diverse locations who are matched to you based on their unique needs and your specialties.",
            "Beyond the Consultation: Recommend communities where your patients can gain emotional support, leading to better outcomes and higher satisfaction ratings.",
            "Grow Professionally: Build a strong online reputation with verified reviews and a trusted profile."
          ]
        },
        {
          "title": "Additional Features for Doctors",
          "icon": faStar,
          "points": [
            "Collaborate with other professionals to tackle complex cases, creating a network of expertise.",
            "Gain access to patient progress dashboards, tracking how your care is impacting lives in real-time.",
            "Engage with community forums to stay updated on the latest medical trends and discussions."
          ]
        }
      ]
    }
  };

  const currentBenefits = data[selectedCategory].benefits;

  const styles = `
    <style>
      .category-nav {
        width: 95%;
        margin: 0 auto;
        border-radius: 20px;
        background-color: transparent;
        overflow: hidden;
        padding: 10px;
        display: flex;
        flex-direction: row !important;
        justify-content: center;
        gap: 20px;
      }

      .category-nav .nav-item {
        flex: 0 0 auto;
        margin: 0 10px;
      }

      .category-nav-link {
        border-radius: 25px !important;
        padding: 15px 30px !important;
        font-size: 16px !important;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff !important;
        background-color: transparent !important;
        transition: all 0.3s ease;
        border: 1px solid rgba(255, 255, 255, 0.5);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
      }

      .icon-wrapper {
        font-size: 2.5rem;
        margin-bottom: 20px;
        color: #ffffff !important;
      }

      .white-icon {
        color: #ffffff !important;
        fill: #ffffff !important;
      }

      .icon-wrapper svg,
      .icon-wrapper svg path {
        color: #ffffff !important;
        fill: #ffffff !important;
      }

      .why-choose-card .icon-wrapper {
        color: #ffffff !important;
      }

      .why-choose-card .icon-wrapper svg,
      .why-choose-card .icon-wrapper svg path {
        color: #ffffff !important;
        fill: #ffffff !important;
      }

      .why-choose-card li:before {
        content: "→";
        position: absolute;
        left: 0;
        color: #ffffff !important;
      }

      /* Rest of your existing styles... */

      @media (max-width: 768px) {
        .category-nav {
          flex-direction: row !important;
          flex-wrap: wrap;
          gap: 10px;
        }
        
        .category-nav-link {
          width: auto;
          padding: 12px 20px !important;
        }
      }

      .nav.nav-pills {
        background-color: transparent !important;
      }
    </style>
  `;

  return (
    <section className="project" id="why-choose">
      <Container>
        <Row>
          <Col size={12}>
            <div className="skill-bx wow zoomIn">
              <TrackVisibility>
                {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                  <h2>Why Choose Dr.Medkit?</h2>
                  <p>Discover how we're revolutionizing healthcare delivery for both patients and healthcare professionals.</p>
                  
                  {/* Category Selection Buttons */}
                  <Nav variant="pills" className="category-nav mb-4 justify-content-center align-items-center">
                    <Nav.Item>
                      <Nav.Link 
                        className={`category-nav-link ${selectedCategory === "forPatients" ? 'active' : ''}`}
                        onClick={() => setSelectedCategory("forPatients")}
                      >
                        <span>For Patients</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link 
                        className={`category-nav-link ${selectedCategory === "forDoctors" ? 'active' : ''}`}
                        onClick={() => setSelectedCategory("forDoctors")}
                        data-category="forDoctors"
                      >
                        <span>For Professionals</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Row>
                    {currentBenefits.map((benefit, index) => (
                      <Col key={index} md={6} className="mb-4">
                        <div className="why-choose-card">
                          <div className="icon-wrapper white-icon">
                            <FontAwesomeIcon icon={benefit.icon} className="white-icon" />
                          </div>
                          <h3>{benefit.title}</h3>
                          <ul>
                            {benefit.points.map((point, idx) => (
                              <li key={idx}>
                                {point.includes(":") ? (
                                  <>
                                    <strong>{point.split(":")[0]}</strong>
                                    :{point.split(":")[1]}
                                  </>
                                ) : (
                                  point
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>}
              </TrackVisibility>
            </div>
          </Col>
        </Row>
      </Container>
      <div dangerouslySetInnerHTML={{ __html: styles }} />
    </section>
  );
}; 