import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import 'animate.css';
import backgroundImage from "../assets/img/3.png"; // Import the background image

console.log("Background Image:", backgroundImage);

export const Projects = () => {
  const [selectedCategory, setSelectedCategory] = React.useState("For Patients");
  
  const projects = [
        {
          "category": "For Patients",
          "features": [
            {
              "title": "Advanced Matching Algorithm",
              "description": "Connect with doctors specializing in your exact needs with our AI-driven matching system. Join patient communities tailored to your verified health conditions.",
              "benefits": [
                "• Personalized doctor recommendations based on symptoms, medical history, and preferences.",
                "• Join verified communities of others who share similar conditions for peer-to-peer support and advice."
              ]
            },
            {
              "title": "AI-Powered Health Assistant",
              "description": "Chat with our intelligent health assistant to log symptoms, receive initial assessments, and prepare anamnesis reports for doctors.",
              "benefits": [
                "• Receive instant initial assessments before consulting a doctor.",
                "• Ensures no important details are missed during symptom collection.",
                "• Supports patients through empathetic, intelligent conversations."
              ]
            },
            {
              "title": "Supportive Communities",
              "description": "Verified by doctors, join communities where patients with shared experiences exchange advice, discuss treatments, and support one another.",
              "benefits": [
                "• Verified and trustworthy environments free from misinformation.",
                "• Opportunities to receive peer recommendations for coping strategies and lifestyle changes.",
                "• Fosters a sense of belonging and emotional support during recovery."
              ]
            },
            {
              "title": "Seamless Online Consultations",
              "description": "Secure, high-quality video meetings with your doctor from the comfort of your home.",
              "benefits": [
                "• Consult from the comfort of your home with no need to travel.",
                "• Encrypted communication ensures privacy and confidentiality.",
                "• Doctors have access to patient data and AI-generated reports for efficient consultations."
              ]
            },
            {
              "title": "Third-Party Integrations",
              "description": "Access at-home medical kits, get tests done at partner laboratories, and receive results directly through our platform.",
              "benefits": [
                "• Convenient access to advanced diagnostic tools without visiting clinics or labs.",
                "• Faster test result processing through partner laboratories.",
                "• Better integration between diagnostic tools and medical consultations."
              ]
            }
          ]
        },
        {
          "category": "For Professionals",
          "features": [
            {
              "title": "AI-Powered Diagnostic Support",
              "description": "Use LLMs to transcribe and analyze patient interactions during consultations. Receive real-time diagnostic suggestions based on cutting-edge AI and medical knowledge bases.",
              "benefits": [
                "• Reduces the cognitive load on doctors, allowing them to focus on patient care.",
                "• Improves diagnostic accuracy with insights based on up-to-date medical databases.",
                "• Enhances the quality of consultations by identifying potential red flags and suggesting next steps."
              ]
            },
            {
              "title": "Advanced Patient Matching",
              "description": "Get connected with patients most suited to your specialties, ensuring meaningful and impactful consultations.",
              "benefits": [
                "• Increases the likelihood of positive outcomes by matching doctors with patients aligned with their specialties.",
                "• Saves time by avoiding mismatches and irrelevant cases.",
                "• Builds a reputation for doctors as trusted experts in their fields."
              ]
            },
            {
              "title": "Secure Patient Management",
              "description": "Access patient histories, test results, and community discussions securely in one place.",
              "benefits": [
                "• Simplifies workflow by keeping all necessary data in one place.",
                "• Ensures patient data is protected with end-to-end encryption and compliance with healthcare regulations like HIPAA.",
                "• Reduces administrative burdens, allowing doctors to focus on care."
              ]
            },
            {
              "title": "Practice Growth Opportunities",
              "description": "Expand your patient base and streamline your workflow with our integrated tools for scheduling, consultations, and follow-ups.",
              "benefits": [
                "• Increases revenue by connecting with more patients.",
                "• Streamlines administrative tasks, allowing doctors to focus on consultations.",
                "• Helps doctors establish a strong online presence and build long-term patient relationships."
              ]
            },
            {
              "title": "Community Verification",
              "description": "Help patients access the right support systems by verifying their conditions and recommending communities for peer interactions.",
              "benefits": [
                "• Guides patients to the right peer networks for advice and support.",
                "• Builds trust between doctors and patients through accurate verifications.",
                "• Allows doctors to provide additional resources beyond clinical care."
              ]
            }
          ]
        }
      ];
    

  // Get features based on selected category
  const currentFeatures = projects.find(p => p.category === selectedCategory)?.features || [];

  return (
    <section className="project" id="projects" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/2.png)`}}>
      <Container>
        <Row>
          <Col size={12}>
            <div className="skill-bx wow zoomIn">
                {
                <div>
                  <h2>Key Features</h2>
                  <p>Explore our innovative healthcare platform's features designed for both patients and healthcare professionals.</p>
                  
                  {/* Category Selection Buttons */}
                  <Nav variant="pills" className="category-nav mb-4 justify-content-center align-items-center">
                    {projects.map((project) => (
                      <Nav.Item key={project.category}>
                        <Nav.Link 
                          className={`category-nav-link ${selectedCategory === project.category ? 'active' : ''}`}
                          onClick={() => setSelectedCategory(project.category)}
                          data-category={project.category === "For Professionals" ? "forDoctors" : ""}
                        >
                          <span>{project.category}</span>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>

                  <Tab.Container id="projects-tabs" defaultActiveKey="0" data-category={selectedCategory === "For Professionals" ? "forDoctors" : ""}>
                    <div className="feature-list-background">
                      <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                        {currentFeatures.map((feature, index) => (
                          <Nav.Item key={index}>
                            <Nav.Link 
                              eventKey={index.toString()} 
                              className="custom-nav-link"
                              data-category={selectedCategory === "For Professionals" ? "forDoctors" : ""}
                            >
                              <span>{feature.title}</span>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Tab.Content id="slideInUp">
                        {currentFeatures.map((feature, index) => (
                          <Tab.Pane eventKey={index.toString()} key={index}>
                            <div className="project-description">
                              <h4>{feature.title}</h4>
                              <p>{feature.description}</p>
                              <div className="benefits-section">
                                <ul>
                                  {feature.benefits.map((benefit, idx) => (
                                    <li key={idx}>{benefit}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
                }
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

// Update the styles block
const styles = `
  <style>
    .custom-nav-link {
      border-radius: 20px;
      transition: all 0.3s ease;
      cursor: pointer;
    }
    .custom-nav-link:hover,
    .custom-nav-link:focus,
    .custom-nav-link.active {
      background-color: #007bff;
      color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
    .custom-nav-link:active {
      transform: scale(0.95);
    }
    .benefits-section {
      margin-top: 20px;
      padding: 20px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 15px;
    }

    .benefits-section h5 {
      color: #007bff;
      margin-bottom: 15px;
    }

    .benefits-section ul {
      list-style-type: none;
      padding-left: 0;
    }

    .benefits-section li {
      padding: 8px 0;
      position: relative;
      padding-left: 25px;
    }

    .benefits-section li:before {
      content: "→";
      position: absolute;
      left: 0;
      color: #007bff;
    }

    .project-description {
      text-align: left;
      padding: 20px;
    }
    .category-nav {
      width: 95%;
      margin: 0 auto;
      border-radius: 20px;
      background: rgba(217, 217, 217, 0.1);
      overflow: hidden;
      padding: 10px;
      display: flex;
      gap: 20px;
      margin-bottom: 30px !important;
    }

    .category-nav-link {
      border-radius: 25px !important;
      padding: 15px 30px !important;
      font-size: 16px !important;
      line-height: 1.2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff !important;
      background-color: transparent !important;
      transition: all 0.3s ease;
      border: 1px solid rgba(255, 255, 255, 0.5);
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .category-nav-link::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.2);
      transition: all 0.3s ease;
      border-radius: 25px;
    }

    .category-nav-link:hover::before,
    .category-nav-link.active::before {
      width: 100%;
    }

    .category-nav-link span {
      position: relative;
      z-index: 1;
    }

    .category-nav-link:hover,
    .category-nav-link.active {
      transform: translateY(-3px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .category-nav-link:active {
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
      .category-nav {
        flex-direction: column;
        gap: 10px;
      }
      
      .category-nav-link {
        width: 100%;
        padding: 12px 20px !important;
      }
    }

    .feature-list-background {
      background: rgba(217, 217, 217, 0.1);
      border-radius: 20px;
      padding: 20px;
      margin-bottom: 30px;
    }
  </style>
`;
