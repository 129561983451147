import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";
import { FaLinkedin } from 'react-icons/fa';
import { SiCrunchbase } from 'react-icons/si';

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img 
              src="/favicon.ico" 
              alt="Logo" 
              className="navbar-logo"
              style={{ width: '100px', height: '100px' }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="#why-choose" className={activeLink === 'why-choose' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('why-choose')}>Why Choose Us?</Nav.Link>
              <Nav.Link href="#how-it-works" className={activeLink === 'how-it-works' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('how-it-works')}>How It Works?</Nav.Link>
              <Nav.Link href="#who-are-we" className={activeLink === 'who-are-we' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('who-are-we')}>Who Are We?</Nav.Link>
              <Nav.Link href="#roadmap" className={activeLink === 'roadmap' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('roadmap')}>Our Project Roadmap</Nav.Link>
              <div className="social-icon-vertical">
                <a 
                  href="https://www.linkedin.com/company/drmedkit/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="social-icon-small"
                >
                  <FaLinkedin />
                </a>
                <a 
                  href="https://www.crunchbase.com/organization/drmedkit" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="social-icon-small"
                >
                  <SiCrunchbase />
                </a>
              </div>
            </Nav>
            <span className="navbar-text">
              <HashLink to='#video'>
                <button className="vvd mvp-btn"><span>Check Our MVP</span></button>
              </HashLink>
              <HashLink to='#connect'>
                <button className="vvd"><span>Let's Connect</span></button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
