import { Row, Col, Container } from "react-bootstrap";
import 'animate.css';
import Duru from '../assets/img/Duru.png';
import Cengiz from '../assets/img/Cengiz.png';
import Ebru from '../assets/img/Ebru.png';
import { FaLinkedin, FaGithub, FaGlobe } from 'react-icons/fa';

const LinkedInIcon = () => <FaLinkedin />;
const GitHubIcon = () => <FaGithub />;
const WebsiteIcon = () => <FaGlobe />;

export const WhoAreWe = () => {
  const team = [
    {
      f: "Founder",
      name: "Mehmet Cengizhan Kınay",
      role: "Project Lead & Backend Developer, Computer Engineering Student Bahçeşehir University",
      description: "Cengiz is a backend developer with strong expertise in C++, Python, Java, Django, Flask, and Nginx. He specializes in building high-performance systems and scalable applications. With experience integrating Python and C++ using Pybind11, he creates efficient, flexible solutions. Cengiz has worked on server-side applications hosted on AWS EC2 and Google Cloud, and his expertise extends to integrating various APIs. He also develops fine-tuned LLMs and TensorFlow models for tailored solutions, always focusing on clean architecture and scalability.",
      imageUrl: Cengiz,
      linkedin: "https://www.linkedin.com/in/thingizkhan-kinik-a59653174/",
      github: "https://github.com/thingizkhan"
    },
    {
      f: "Founder",
      name: "Duru Nef Özmen",
      role: "Data Scientist & Frontend Developer, Computer Science Student Sabancı University",
      description: "Duru is a skilled data scientist with experience in machine learning, data manipulation, and Python. She is proficient in both SQL and NoSQL databases, adding a strong foundation in data management to the project. As the Frontend Developer, she specializes in React, React Native, and web development, ensuring the platform is intuitive and user-friendly across both mobile and web interfaces. Her expertise in cloud integration further enhances the user experience. Duru's role focuses on creating an efficient, seamless user interface, while leveraging her data science and machine learning skills to improve user interactions.",
      imageUrl: Duru,
      linkedin: "https://www.linkedin.com/in/durunef/",
      github: "https://github.com/durunef"
    },
    {
      f: "Co-Founder",
      name: "Ebru Bellek Karakaş",
      role: "Chest Diseases and Tuberculosis Specialist",
      description: "Dr. Ebru Bellek Karakaş is a specialist in Chest Diseases and Tuberculosis, with extensive experience from institutions like Cerrahpaşa Medical Faculty Hospital and St. Luke's Roosevelt Hospital in New York. She has also represented the American College of Chest Physicians in Turkey. Dedicated to advancing pioneering healthcare technologies, Dr. Karakaş will provide key medical data for AI fine-tuning in this project, ensuring accurate and reliable health recommendations. She currently runs her own clinic in Ataşehir, serving both Turkish and international patients.",
      imageUrl: Ebru,
      linkedin: "https://www.linkedin.com/in/ebru-bellek-karakaş-2ab50a4/",
      website: "https://www.ebrubellek.com"
    }
  ];

  return (
    <section className="who-are-we" id="who-are-we">
      <Container>
        <div className="skill-bx wow zoomIn">
          <Row>
            <Col>
              <h1>Who Are We</h1>
            </Col>
          </Row>
          <Row>
            {team.map((member, index) => (
              <Col lg={4} md={6} key={index}>
                <div className="who-are-we-box">
                  <div className="social-icons-vertical">
                    <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="social-icon">
                      <LinkedInIcon />
                    </a>
                    {member.github && (
                      <a href={member.github} target="_blank" rel="noopener noreferrer" className="social-icon">
                        <GitHubIcon />
                      </a>
                    )}
                    {member.website && (
                      <a href={member.website} target="_blank" rel="noopener noreferrer" className="social-icon">
                        <WebsiteIcon />
                      </a>
                    )}
                  </div>
                  <img src={member.imageUrl} alt={member.name} />
                  <h3>{member.f}</h3>
                  <h3>{member.name}</h3>
                  <h4>{member.role}</h4>
                  <p>{member.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </section>
  );
}
