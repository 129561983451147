import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import './ProjectRoadmap.css';

export const ProjectRoadmap = () => {
  const [activeCard, setActiveCard] = useState(null);

  const timelineData = [
    {
      id: 1,
      date: "August - September 2024",
      title: "Defining Project Goals, Target Audience, and Requirements",
      content: [
        "Plan the system infrastructure for AI-powered personalized health recommendations.",
        "Analyze local and global competitor platforms.",
        "Identify user needs and gaps in the market.",
        "Define the overall project idea aligned with user requirements.",
        "Conduct interviews with doctors and patients to detail project requirements."
      ]
    },
    {
      id: 2,
      date: "October 2024",
      title: "Prototype Development",
      content: [
        "Develop a prototype for the basic patient-doctor matching system.",
        "Prepare the first version of the user interface designs.",
        "Test the initial prototype with a small user group.",
        "Collect feedback and implement improvements based on user insights."
      ]
    },
    {
      id: 3,
      date: "November - December 2024",
      title: "Frontend Development for MVP: Building the Core Platform",
      content: [
        "Complete the React-based frontend infrastructure.",
        "Develop user login and profile creation modules.",
        "Deploy the first version of the patient-doctor matching system.",
        "Build appointment management and patient data viewing tools."
      ]
    },
    {
      id: 4,
      date: "January - February 2025",
      title: "Integration of AI Module for MVP",
      content: [
        "Develop personalized health recommendations based on user profiles.",
        "Implement AI models to analyze patient data and provide reports for doctors.",
        "Test the accuracy and performance of the AI model.",
        "Optimize the module based on user needs and feedback."
      ]
    },
    {
      id: 5,
      date: "March 2025",
      title: "Mobile Compatibility and System Optimization",
      content: [
        "Ensure the platform is fully compatible with mobile devices.",
        "Optimize system speed and data flow.",
        "Apply improvements to enhance the overall user experience."
      ]
    },
    {
      id: 6,
      date: "April 2025",
      title: "User Feedback, Doctor Reviews, and Third-Party Lab Collaborations",
      content: [
        "Analyze user experiences and identify potential issues.",
        "Conduct one-on-one interviews with doctors to assess system suitability.",
        "Initiate and finalize partnerships with third-party laboratories to integrate diagnostic test data."
      ]
    },
    {
      id: 7,
      date: "May 2025",
      title: "Risk Management and Continuous Evaluation",
      content: [
        "Monitor data security, user privacy, and system performance.",
        "Regularly evaluate and improve AI model accuracy.",
        "Implement system improvements based on user feedback."
      ]
    },
    {
      id: 8,
      date: "June 2025",
      title: "Program Launch to Users",
      content: [
        "Release the full version of the platform to users.",
        "Launch digital marketing campaigns to reach a broader user base.",
        "Focus on the Turkish market initially, followed by an expansion strategy into global markets."
      ]
    }
  ];
  

  const toggleCard = (id) => {
    if (activeCard === id) {
      setActiveCard(null);
    } else {
      setActiveCard(id);
    }
  };

  return (
    <section className="project" id="roadmap">
      <Container>
        <Row>
          <Col size={12}>
            <div className="skill-bx">
              <h2>Our Project Roadmap</h2>
              <p>Discover our journey and future milestones as we revolutionize healthcare.</p>
              
              <div className="timeline-container">
                {timelineData.map((item, index) => (
                  <div 
                    key={item.id} 
                    className={`timeline-item ${item.id % 2 === 0 ? 'right' : 'left'} ${
                      index === 3 ? 'current' : ''
                    }`}
                  >
                    <div className="timeline-dot"></div>
                    <div className="timeline-date">{item.date}</div>
                    <div 
                      className={`timeline-card ${activeCard === item.id ? 'active' : ''}`}
                      onClick={() => toggleCard(item.id)}
                    >
                      <h3>{item.title}</h3>
                      <div className="timeline-content">
                        <ul>
                          {item.content.map((point, index) => (
                            <li key={index}>{point}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}; 