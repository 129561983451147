import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.png";
import 'animate.css';


export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
              {
                <div>
                  <img src={logo} alt="Logo" />
                </div>
              }
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <p>© 2024 Dr.Medkit Health & Dr.Medkit Software. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
